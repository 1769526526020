import ReactGA from "react-ga4";

const loginScreenEventTypes = {
  LOGIN_PHONE_NUMBER_INPUT_FIELD_CLICK: "LOGIN_PHONE_NUMBER_INPUT_FIELD_CLICK",
  LOGIN_WITH_USER_NAME_FIELD_CLICK: "LOGIN_WITH_USER_NAME_FIELD_CLICK",
  LOGIN_WITH_PASSWORD_FIELD_CLICK: "LOGIN_WITH_PASSWORD_FIELD_CLICK",
  LOGIN_SHOW_PASSWORD_CLICK: "LOGIN_SHOW_PASSWORD_CLICK",
  LOGIN_HIDE_PASSWORD_CLICK: "LOGIN_HIDE_PASSWORD_CLICK",
  LOGIN_WITH_PHONE_NUMBER_CLICK: "LOGIN_WITH_PHONE_NUMBER_CLICK",
  LOGIN_WITH_USER_NAME_CLICK: "LOGIN_WITH_USER_NAME_CLICK",
  LOGIN_TO_VM_BUTTON_CLICK_WITH_PHONE_NUMBER:
    "LOGIN_TO_VM_BUTTON_CLICK_WITH_PHONE_NUMBER",
  LOGIN_TO_VM_BUTTON_CLICK_WITH_USER_NAME:
    "LOGIN_TO_VM_BUTTON_CLICK_WITH_USER_NAME",
  REGISTER_BUTTON_CLICK: "REGISTER_BUTTON_CLICK",
  FORGOT_PASSWORD_BUTTON_CLICK: "FORGOT_PASSWORD_BUTTON_CLICK",
  LOGIN_LANGUAGE_EN_BUTTON_CLICK: "LOGIN_LANGUAGE_EN_BUTTON_CLICK",
  LOGIN_LANGUAGE_HI_BUTTON_CLICK: "LOGIN_LANGUAGE_HI_BUTTON_CLICK",
  LOGIN_LANGUAGE_TE_BUTTON_CLICK: "LOGIN_LANGUAGE_TE_BUTTON_CLICK",
  LOGIN_VIEW_TERMS_CONDITIONS_CLICK: "LOGIN_VIEW_TERMS_CONDITIONS_CLICK"
};

const registrationScreensEventTypes = {
  REGISTRATION_LOCATION_PERMISSION_STATUS:
    "REGISTRATION_LOCATION_PERMISSION_STATUS",
  REGISTRATION_OWNER_NAME_FIELD_CLICK: "REGISTRATION_OWNER_NAME_FIELD_CLICK",
  REGISTRATION_PHONE_NUMBER_FIELD_CLICK:
    "REGISTRATION_PHONE_NUMBER_FIELD_CLICK",
  REGISTRATION_EMAIL_ID_FIELD_CLICK: "REGISTRATION_EMAIL_ID_FIELD_CLICK",
  REGISTRATION_PINCODE_FIELD_CLICK: "REGISTRATION_PINCODE_FIELD_CLICK",
  REGISTRATION_GST_NUMBER_FIELD_CLICK: "REGISTRATION_GST_NUMBER_FIELD_CLICK",
  REGISTRATION_REFERRAL_CODE_FIELD_CLICK: "REG_REFERRAL_CODE_FIELD_CLICK",
  REGISTRATION_UPLOAD_DRUG_LICENSE_CLICK: "REG_UPLOAD_DRUG_LICENSE_CLICK",
  REGISTRATION_REMOVE_DRUG_LICENSE_CLICK: "REG_REMOVE_DRUG_LICENSE_CLICK",
  REGISTRATION_AGREE_FOR_WHATSAPP_NOTIFICATIONS_CLICK:
    "REG_AGREE_FOR_WHATSAPP_NOTIFICATIONS_CLICK",
  REGISTRATION_AGREE_FOR_TERMS_CONDITIONS_CLICK:
    "REG_AGREE_FOR_TERMS_CONDITIONS_CLICK",
  REGISTRATION_VIEW_TERMS_CONDITIONS_CLICK: "REG_VIEW_TERMS_CONDITIONS_CLICK",
  REGISTRATION_BUTTON_CLICK: "REGISTRATION_BUTTON_CLICK",
  REGISTRATION_PHONE_NUMBER_EXISTING_MODAL_VISIBLE:
    "REG_PHONE_NUMBER_EXISTING_MODAL_VISIBLE",
  REGISTRATION_PHONE_NUMBER_EXISTING_MODAL_CLOSED:
    "REG_PHONE_NUMBER_EXISTING_MODAL_CLOSED",
  REGISTRATION_PHONE_NUMBER_EXISTING_MODAL_RESET_CLICK:
    "REG_PHONE_NUMBER_EXISTING_MODAL_RESET_CLICK"
};

const forgotPasswordScreen = {
  FORGOT_PASSWORD_PHONE_NUMBER_FIELD_CLICK:
    "FORGOT_PASSWORD_PHONE_NUMBER_FIELD_CLICK",
  FORGOT_PASSWORD_SEND_OTP_BUTTON_CLICK: "FORGOT_PASSWORD_SEND_OTP_BUTTON_CLICK"
};

const otpScreen = {
  OTP_RESEND_BUTTON_CLICK: "OTP_RESEND_BUTTON_CLICK",
  OTP_VERIFY_BUTTON_CLICK: "OTP_VERIFY_BUTTON_CLICK"
};

const confirmPasswordScreen = {
  CONFIRM_PASSWORD_NEW_PASSWORD_FIELD_CLICK: "CP_NEW_PASSWORD_FIELD_CLICK",
  CONFIRM_PASSWORD_FIELD_CLICK: "CONFIRM_PASSWORD_FIELD_CLICK",
  CONFIRM_PASSWORD_ON_SUBMIT_CLICK: "CONFIRM_PASSWORD_ON_SUBMIT_CLICK"
};

const notificationScreen = {
  NOTIFICATION_SCREEN_VISITED: "NOTIFICATION_SCREEN_VISITED",
  NOTIFICATION_ON_CLICK: "NOTIFICATION_ON_CLICK"
};

const analyticsScreen = {
  ANALYTICS_SCREEN_VISITED: "ANALYTICS_SCREEN_VISITED",
  ANALYTICS_ON_DATE_PICKER: "ANALYTICS_ON_DATE_PICKER",
  ANALYTICS_ON_TOTAL_ORDERS: "ANALYTICS_ON_TOTAL_ORDERS",
  ANALYTICS_ON_RECEIVED_ORDERS: "ANALYTICS_ON_RECEIVED_ORDERS",
  ANALYTICS_ON_PROCESSING_ORDERS: "ANALYTICS_ON_PROCESSING_ORDERS",
  ANALYTICS_ON_INVOICED_ORDERS: "ANALYTICS_ON_INVOICED_ORDERS",
  ANALYTICS_ON_CANCELED_ORDERS: "ANALYTICS_ON_CANCELED_ORDERS",
  DASHBOARD_BANNER_CLICK: "DASHBOARD_BANNER_CLICK",
  ANALYTICS_ON_SUPPLIER_SELECT: "ANALYTICS_ON_SUPPLIER_SELECT"
};

const allOrderScreen = {
  ALL_ORDERS_SCREEN_VISITED: "ALL_ORDERS_SCREEN_VISITED",
  ALL_ORDERS_ON_SEARCH_CLICK: "ALL_ORDERS_ON_SEARCH_CLICK",
  ALL_ORDERS_ON_SEARCH_CLEAR_CLICK: "ALL_ORDERS_ON_SEARCH_CLEAR_CLICK",
  ALL_ORDERS_CALENDER_UPDATED: "ALL_ORDERS_CALENDER_UPDATED",
  ALL_ORDERS_FILTER_UPDATED: "ALL_ORDERS_FILTER_UPDATED",
  ALL_ORDERS_ROW_SELECTION: "ALL_ORDERS_ROW_SELECTION",
  ALL_ORDERS_ROW_OPTIONS_SELECT: "ALL_ORDERS_ROW_OPTIONS_SELECT",
  ALL_ORDERS_ORDER_DOWNLOAD: "ALL_ORDERS_ORDER_DOWNLOAD",
  ALL_ORDERS_ORDER_PRINT: "ALL_ORDERS_ORDER_PRINT",
  ALL_ORDERS_ORDER_INVOICE: "ALL_ORDERS_ORDER_INVOICE",
  ALL_ORDERS_ORDER_UPDATE_CUSTOMER: "ALL_ORDERS_ORDER_UPDATE_CUSTOMER",
  ALL_ORDERS_ON_ORDER_ITEM_CLICK: "ALL_ORDERS_ON_ORDER_ITEM_CLICK",
  ALL_ORDERS_ON_DISTRIBUTOR_VIEW_CLICK: "ALL_ORDERS_ON_DISTRIBUTOR_VIEW_CLICK",
  ALL_ORDERS_ON_DATE_VIEW_CLICK: "ALL_ORDERS_ON_DATE_VIEW_CLICK",
  ALL_ORDERS_ON_PRODUCT_VIEW_CLICK: "ALL_ORDERS_ON_PRODUCT_VIEW_CLICK",
  ALL_ORDERS_ON_SHORTAGE_VIEW_CLICK: "ALL_ORDERS_ON_SHORTAGE_VIEW_CLICK"
};

const orderDetailsScreen = {
  ORDER_DETAILS_SCREEN_VISITED: "ORDER_DETAILS_SCREEN_VISITED",
  ORDER_DETAILS_ON_SEARCH_CLICK: "ORDER_DETAILS_ON_SEARCH_CLICK",
  ORDER_DETAILS_ON_SEARCH_CLEAR_CLICK: "ORDER_DETAILS_ON_SEARCH_CLEAR_CLICK",
  ORDER_DETAILS_ON_SELECT_ALL_PRODUCTS: "ORDER_DETAILS_ON_SELECT_ALL_PRODUCTS",
  ORDER_DETAILS_ON_SELECT_PRODUCT: "ORDER_DETAILS_ON_SELECT_PRODUCT",
  ORDER_DETAILS_ON_REORDER_CLICK: "ORDER_DETAILS_ON_REORDER_CLICK",
  ORDER_DETAILS_ON_EXPORT: "ORDER_DETAILS_ON_EXPORT",
  ORDER_DETAILS_ON_UPDATE_CUSTOMER: "ORDER_DETAILS_ON_UPDATE_CUSTOMER",
  ORDER_DETAILS_ON_UPDATE_ORDER: "ORDER_DETAILS_ON_UPDATE_ORDER",
  ORDER_DETAILS_ON_MARK_AS_DELIVERED: "ORDER_DETAILS_ON_MARK_AS_DELIVERED",
  ORDER_DETAILS_ON_DELIVERY_MODAL_CLOSE:
    "ORDER_DETAILS_ON_DELIVERY_MODAL_CLOSE",
  ORDER_DETAILS_ON_PRE_CART: "ORDER_DETAILS_ON_PRE_CART",
  ORDER_DETAILS_ON_PRINT: "ORDER_DETAILS_ON_PRINT",
  ORDER_DETAILS_ON_DOWNLOAD: "ORDER_DETAILS_ON_DOWNLOAD"
};

const paymentsScreen = {
  PAYMENTS_SCREEN_VISITED: "PAYMENTS_SCREEN_VISITED",
  PAYMENTS_ON_SHOW_PAYMENTS_HISTORY: "PAYMENTS_ON_SHOW_PAYMENTS_HISTORY",
  PAYMENTS_ON_SHOW_OUTSTANDING: "PAYMENTS_ON_SHOW_OUTSTANDING",
  PAYMENTS_ON_SEARCH: "PAYMENTS_ON_SEARCH",
  PAYMENTS_ON_SEARCH_CLEAR: "PAYMENTS_ON_SEARCH_CLEAR",
  PAYMENTS_ON_OUTSTANDING_PAY: "PAYMENTS_ON_OUTSTANDING_PAY",
  PAYMENTS_ON_OUTSTANDING_COLLECT: "PAYMENTS_ON_OUTSTANDING_COLLECT",
  PAYMENTS_ON_OUTSTANDING_REQUEST: "PAYMENTS_ON_OUTSTANDING_REQUEST",
  PAYMENTS_ON_AMOUNT_FIELD: "PAYMENTS_ON_AMOUNT_FIELD",
  PAYMENTS_ON_CLOSE_PAY_MODAL: "PAYMENTS_ON_CLOSE_PAY_MODAL",
  PAYMENTS_ON_OPEN_PAY_MODAL: "PAYMENTS_ON_OPEN_PAY_MODAL",
  PAYMENTS_ON_MODAL_PAY: "PAYMENTS_ON_MODAL_PAY",
  PAYMENTS_ON_MODAL_COLLECT: "PAYMENTS_ON_MODAL_COLLECT",
  PAYMENTS_ON_MODAL_REQUEST: "PAYMENTS_ON_MODAL_REQUEST",
  PAYMENTS_ON_CASH_MODE: "PAYMENTS_ON_CASH_MODE",
  PAYMENTS_ON_CHEQUE_MODE: "PAYMENTS_ON_CHEQUE_MODE",
  PAYMENTS_ON_NEFT_OR_RTGS_MODE: "PAYMENTS_ON_NEFT_OR_RTGS_MODE",
  PAYMENTS_ON_RECEPT_NUMBER_FIELD: "PAYMENTS_ON_RECEPT_NUMBER_FIELD",
  PAYMENTS_ON_RECEPT_DATE_FIELD: "PAYMENTS_ON_RECEPT_DATE_FIELD",
  PAYMENTS_ON_INVOICE_NUMBER_FIELD: "PAYMENTS_ON_INVOICE_NUMBER_FIELD",
  PAYMENTS_ON_INVOICE_AMOUNT_FIELD: "PAYMENTS_ON_INVOICE_AMOUNT_FIELD",
  PAYMENTS_ON_INVOICE_DATE_FIELD: "PAYMENTS_ON_INVOICE_DATE_FIELD",
  PAYMENTS_ON_ADD_INVOICE: "PAYMENTS_ON_ADD_INVOICE",
  PAYMENTS_ON_REMOVE_INVOICE: "PAYMENTS_ON_REMOVE_INVOICE",
  PAYMENTS_HISTORY_SCREEN_VISITED: "PAYMENTS_HISTORY_SCREEN_VISITED",
  PAYMENTS_HISTORY_ON_SEARCH: "PAYMENTS_HISTORY_ON_SEARCH",
  PAYMENTS_HISTORY_ON_SEARCH_CLEAR: "PAYMENTS_HISTORY_ON_SEARCH_CLEAR",
  PAYMENTS_HISTORY_CALENDER_UPDATED: "PAYMENTS_HISTORY_CALENDER_UPDATED",
  PAYMENTS_HISTORY_FILTER_UPDATED: "PAYMENTS_HISTORY_FILTER_UPDATED",
  PAYMENTS_HISTORY_ON_DETAILS_MODAL_CLOSE:
    "PAYMENTS_HISTORY_ON_DETAILS_MODAL_CLOSE",
  PAYMENTS_HISTORY_ON_DOWNLOAD_REPORT: "PAYMENTS_HISTORY_ON_DOWNLOAD_REPORT",
  PAYMENTS_HISTORY_ON_SELECT_DATE_RANGE:
    "PAYMENTS_HISTORY_ON_SELECT_DATE_RANGE",
  PAYMENTS_HISTORY_DOWNLOAD_REPORT_MODAL:
    "PAYMENTS_HISTORY_DOWNLOAD_REPORT_MODAL"
};

const invoicesScreen = {
  INVOICES_SCREEN_VISITED: "INVOICES_SCREEN_VISITED",
  INVOICES_ON_SEARCH: "INVOICES_ON_SEARCH",
  INVOICES_ON_SEARCH_CLEAR: "INVOICES_ON_SEARCH_CLEAR",
  INVOICES_ON_MODAL: "INVOICES_ON_MODAL",
  INVOICES_ON_MODAL_CLOSE: "INVOICES_ON_MODAL_CLOSE",
  INVOICES_ON_PAYMENT_MODAL: "INVOICES_ON_PAYMENT_MODAL",
  INVOICES_ON_PAYMENT_MODAL_CLOSE: "INVOICES_ON_PAYMENT_MODAL_CLOSE",
  INVOICES_ON_SELECT_SUPPLIER: "INVOICES_ON_SELECT_SUPPLIER",
  INVOICES_ON_SELECT_INVOICES: "INVOICES_ON_SELECT_INVOICES",
  INVOICES_PAY_ONLINE: "INVOICES_PAY_ONLINE",
  INVOICES_ON_XLSX_DOWNLOAD: "INVOICES_ON_XLSX_DOWNLOAD",
  INVOICES_ON_CSV_DOWNLOAD: "INVOICES_ON_CSV_DOWNLOAD"
};

const invoiceDetailsScreen = {
  INVOICE_DETAILS_SCREEN_VISITED: "INVOICE_DETAILS_SCREEN_VISITED",
  INVOICE_DETAILS_ON_BACK_CLICK: "INVOICE_DETAILS_ON_BACK_CLICK",
  INVOICE_DETAILS_ON_SEARCH: "INVOICE_DETAILS_ON_SEARCH",
  INVOICE_DETAILS_ON_SEARCH_CLEAR: "INVOICE_DETAILS_ON_SEARCH_CLEAR",
  INVOICE_DETAILS_ON_XLSX_DOWNLOAD: "INVOICE_DETAILS_ON_XLSX_DOWNLOAD",
  INVOICE_DETAILS_ON_CSV_DOWNLOAD: "INVOICE_DETAILS_ON_CSV_DOWNLOAD",
  INVOICE_DETAILS_ON_PAYMENT_MODAL: "INVOICE_DETAILS_ON_PAYMENT_MODAL",
  INVOICE_DETAILS_ON_PAYMENT_MODAL_CLOSE:
    "INVOICE_DETAILS_ON_PAYMENT_MODAL_CLOSE"
};

const suppliersScreen = {
  SUPPLIERS_SCREEN_VISITED: "SUPPLIERS_SCREEN_VISITED",
  SUPPLIERS_ON_SEARCH: "SUPPLIERS_ON_SEARCH",
  SUPPLIERS_ON_SEARCH_CLEAR: "SUPPLIERS_ON_SEARCH_CLEAR",
  SUPPLIERS_ON_ADD_SUPPLIER: "SUPPLIERS_ON_ADD_SUPPLIER",
  SUPPLIERS_ON_DELETE_SUPPLIER: "SUPPLIERS_ON_DELETE_SUPPLIER"
};

const addSupplierScreen = {
  ADD_SUPPLIER_SCREEN_VISITED: "ADD_SUPPLIER_SCREEN_VISITED",
  ADD_SUPPLIER_ON_BACK_CLICK: "ADD_SUPPLIER_ON_BACK_CLICK",
  ADD_SUPPLIER_ON_SEARCH: "ADD_SUPPLIER_ON_SEARCH",
  ADD_SUPPLIER_ON_SEARCH_CLEAR: "ADD_SUPPLIER_ON_SEARCH_CLEAR",
  ADD_SUPPLIER_ON_ADD_SUPPLIER: "ADD_SUPPLIER_ON_ADD_SUPPLIER",
  ADD_SUPPLIER_ON_SELECT_DIVISIONS: "ADD_SUPPLIER_ON_SELECT_DIVISIONS",
  SUGGEST_SUPPLIER_MODAL_OPEN: "SUGGEST_SUPPLIER_MODAL_OPEN",
  SUGGEST_SUPPLIER_MODAL_CLOSE: "SUGGEST_SUPPLIER_MODAL_CLOSE",
  SUGGEST_SUPPLIER_NAME_FIELD: "SUGGEST_SUPPLIER_NAME_FIELD",
  SUGGEST_SUPPLIER_PHONE_NUMBER_FIELD: "SUGGEST_SUPPLIER_PHONE_NUMBER_FIELD",
  SUGGEST_SUPPLIER_CITY_FIELD: "SUGGEST_SUPPLIER_CITY_FIELD",
  SUGGEST_SUPPLIER_ON_SUBMIT: "SUGGEST_SUPPLIER_ON_SUBMIT"
};

const creditScreen = {
  CREDIT_SCREEN_VISITED: "CREDIT_SCREEN_VISITED",
  CREDIT_ON_TERMS_OF_SERVICE: "CREDIT_ON_TERMS_OF_SERVICE",
  CREDIT_ON_TERMS_OF_SERVICE_AGREE: "CREDIT_ON_TERMS_OF_SERVICE_AGREE",
  CREDIT_ON_APPLY: "CREDIT_ON_APPLY",
  CREDIT_ON_CONSENT_SAVE: "CREDIT_ON_CONSENT_SAVE",
  CREDIT_ON_COMPANY_APPLY_LINK: "CREDIT_ON_COMPANY_APPLY_LINK",
  CREDIT_MODAL_CLOSE: "CREDIT_MODAL_CLOSE",
  CREDIT_ON_PROVIDER_SELECT: "CREDIT_ON_PROVIDER_SELECT",
  CREDIT_ON_SUBMIT: "CREDIT_ON_SUBMIT"
};

const inventoryScreen = {
  INVENTORY_SCREEN_VISITED: "INVENTORY_SCREEN_VISITED",
  INVENTORY_ON_SEARCH: "INVENTORY_ON_SEARCH",
  INVENTORY_ON_SEARCH_CLEAR: "INVENTORY_ON_SEARCH_CLEAR",
  INVENTORY_ON_UPLOAD_INVENTORY: "INVENTORY_ON_UPLOAD_INVENTORY",
  INVENTORY_ON_MAX_QTY: "INVENTORY_ON_MAX_QTY",
  INVENTORY_ON_HIDE_CLICK: "INVENTORY_ON_HIDE_CLICK",
  INVENTORY_ON_UNHIDE_CLICK: "INVENTORY_ON_UNHIDE_CLICK",
  INVENTORY_ON_EDIT_CLICK: "INVENTORY_ON_EDIT_CLICK",
  INVENTORY_ON_FILE_REMOVE_CLICK: "INVENTORY_ON_FILE_REMOVE_CLICK",
  INVENTORY_ON_FILE_SUBMIT_CLICK: "INVENTORY_ON_FILE_SUBMIT_CLICK",
  INVENTORY_ON_SAMPLE_FILE_DOWNLOAD_CLICK:
    "INVENTORY_ON_SAMPLE_FILE_DOWNLOAD_CLICK"
};

const customersScreen = {
  CUSTOMERS_SCREEN_VISITED: "CUSTOMERS_SCREEN_VISITED",
  CUSTOMERS_ON_SEARCH: "CUSTOMERS_ON_SEARCH",
  CUSTOMERS_ON_SEARCH_CLEAR: "CUSTOMERS_ON_SEARCH_CLEAR",
  CUSTOMERS_ON_FILTER_CHANGE: "CUSTOMERS_ON_FILTER_CHANGE",
  CUSTOMERS_ON_BLOCK_APPROVED_USERS: "CUSTOMERS_ON_BLOCK_APPROVED_USERS",
  CUSTOMERS_ON_APPROVED_BLOCKED_USERS: "CUSTOMERS_ON_APPROVED_BLOCKED_USERS",
  CUSTOMERS_ON_SHOW_ONLY_NON_ASSOCIATED:
    "CUSTOMERS_ON_SHOW_ONLY_NON_ASSOCIATED",
  CUSTOMERS_ON_SHOW_ALL_CUSTOMER: "CUSTOMERS_ON_SHOW_ALL_CUSTOMER",
  CUSTOMERS_ON_EDIT_PARTY_CODE: "CUSTOMERS_ON_EDIT_PARTY_CODE",
  CUSTOMERS_ON_UPDATE_PARTY_CODE: "CUSTOMERS_ON_UPDATE_PARTY_CODE",
  CUSTOMERS_ON_DRUG_LICENSE_VIEWED: "CUSTOMERS_ON_DRUG_LICENSE_VIEWED",
  CUSTOMERS_ON_SETTING_OPEN: "CUSTOMERS_ON_SETTING_OPEN",
  CUSTOMERS_ON_SETTING_UPDATED: "CUSTOMERS_ON_SETTING_UPDATED",
  CUSTOMERS_ON_CUSTOMER_OPENED: "CUSTOMERS_ON_CUSTOMER_OPENED",
  CUSTOMERS_ON_CUSTOMER_CLOSED: "CUSTOMERS_ON_CUSTOMER_CLOSED"
};

const landingPage = {
  LANDING_SCREEN_VISITED: "LANDING_SCREEN_VISITED",
  LANDING_ON_LOGO_CLICK: "LANDING_ON_LOGO_CLICK",
  LANDING_ON_LOGIN_CLICK: "LANDING_ON_LOGIN_CLICK",
  LANDING_ON_BOOK_DEMO_CLICK: "LANDING_ON_BOOK_DEMO_CLICK",
  LANDING_ON_MOBILE_KNOW_MORE_CLICK: "LANDING_ON_MOBILE_KNOW_MORE_CLICK",
  LANDING_ON_PARTNERSHIP_KNOW_MORE_CLICK:
    "LANDING_ON_PARTNERSHIP_KNOW_MORE_CLICK",
  LANDING_ON_ANDROID_APP_DOWNLOAD_CLICK:
    "LANDING_ON_ANDROID_APP_DOWNLOAD_CLICK",
  LANDING_ON_IOS_APP_DOWNLOAD_CLICK: "LANDING_ON_IOS_APP_DOWNLOAD_CLICK"
};

const ledgerScreen = {
  LEDGER_SCREEN_VISITED: "LEDGER_SCREEN_VISITED",
  LEDGER_ON_SEARCH: "LEDGER_ON_SEARCH",
  LEDGER_ON_SEARCH_CLEAR: "LEDGER_ON_SEARCH_CLEAR",
  LEDGER_ON_UPLOAD_LEDGER: "LEDGER_ON_UPLOAD_LEDGER",
  LEDGER_ON_UPLOADED_LEDGER: "LEDGER_ON_UPLOADED_LEDGER",
  LEDGER_ON_EDIT_LEDGER: "LEDGER_ON_EDIT_LEDGER",
  LEDGER_ON_UPDATE_LEDGER: "LEDGER_ON_UPDATE_LEDGER"
};

const representativeScreen = {
  REPRESENTATIVE_SCREEN_VISITED: "REPRESENTATIVE_SCREEN_VISITED",
  REPRESENTATIVE_ON_SEARCH: "REPRESENTATIVE_ON_SEARCH",
  REPRESENTATIVE_ON_SEARCH_CLEAR: "REPRESENTATIVE_ON_SEARCH_CLEAR",
  REPRESENTATIVE_ON_CUSTOM_STATUS_FILTER:
    "REPRESENTATIVE_ON_CUSTOM_STATUS_FILTER",
  REPRESENTATIVE_ON_ADD_REP: "REPRESENTATIVE_ON_ADD_REP",
  REPRESENTATIVE_ON_CHECK_REP_ATTENDANCE:
    "REPRESENTATIVE_ON_CHECK_REP_ATTENDANCE",
  REPRESENTATIVE_ON_UPDATE: "REPRESENTATIVE_ON_UPDATE",
  REPRESENTATIVE_ON_ADDED_REP: "REPRESENTATIVE_ON_ADDED_REP",
  REPRESENTATIVE_ON_ADDED_REPS: "REPRESENTATIVE_ON_ADDED_REPS"
};

const attendanceScreen = {
  ATTENDANCE_SCREEN_VISITED: "ATTENDANCE_SCREEN_VISITED",
  ATTENDANCE_REP_SELECT: "ATTENDANCE_REP_SELECT",
  ATTENDANCE_DATES_SELECT: "ATTENDANCE_DATES_SELECT",
  ATTENDANCE_TRACK_REP: "ATTENDANCE_TRACK_REP"
};

const trackingScreen = {
  TRACKING_SCREEN_VISITED: "TRACKING_SCREEN_VISITED",
  TRACKING_REP_SELECT: "TRACKING_REP_SELECT",
  TRACKING_DATE_SELECT: "TRACKING_DATE_SELECT"
};

const settingsScreen = {
  SETTINGS_SCREEN_VISITED: "SETTINGS_SCREEN_VISITED",
  SETTINGS_CONFIG_EDITED: "SETTINGS_CONFIG_EDITED",
  SETTINGS_CONFIG_UPDATED: "SETTINGS_CONFIG_UPDATED"
};

const ledgerOrder = {
  LEDGER_ORDER_SCREEN_VISITED: "LEDGER_ORDER_SCREEN_VISITED",
  LEDGER_ORDER_LEDGER_SELECTION: "LEDGER_ORDER_LEDGER_SELECTION",
  LEDGER_ORDER_PRODUCT_SELECTION: "LEDGER_ORDER_PRODUCT_SELECTION",
  LEDGER_ORDER_ON_FILTER_BY_OFFER: "LEDGER_ORDER_ON_FILTER_BY_OFFER",
  LEDGER_ORDER_ON_REMOVE_FILTER_BY_OFFER:
    "LEDGER_ORDER_ON_REMOVE_FILTER_BY_OFFER",
  LEDGER_ORDER_ON_ADD_PRODUCT: "LEDGER_ORDER_ON_ADD_PRODUCT",
  LEDGER_ORDER_ON_REMOVE_PRODUCT: "LEDGER_ORDER_ON_REMOVE_PRODUCT",
  LEDGER_ORDER_ON_QTY: "LEDGER_ORDER_ON_QTY",
  LEDGER_ORDER_ON_FREE: "LEDGER_ORDER_ON_FREE",
  LEDGER_ORDER_ON_DISCOUNT: "LEDGER_ORDER_ON_DISCOUNT",
  LEDGER_ORDER_ON_PLACE_ORDER: "LEDGER_ORDER_ON_PLACE_ORDER"
};

const placeOrderScreen = {
  PLACE_ORDER_SCREEN_VISITED: "PLACE_ORDER_SCREEN_VISITED",
  PLACE_ORDER_ON_SEARCH_PRODUCT: "PLACE_ORDER_ON_SEARCH_PRODUCT",
  PLACE_ORDER_ON_BULK_ORDER: "PLACE_ORDER_ON_BULK_ORDER",
  PLACE_ORDER_ON_ADD_TO_CART: "PLACE_ORDER_ON_ADD_TO_CART",
  PLACE_ORDER_ON_DELETE_PRODUCT: "PLACE_ORDER_ON_DELETE_PRODUCT",
  PLACE_ORDER_ON_QTY_ADD: "PLACE_ORDER_ON_QTY_ADD",
  PLACE_ORDER_ON_QTY_EDIT: "PLACE_ORDER_ON_QTY_EDIT",
  PLACE_ORDER_ON_UPDATE_PRODUCT: "PLACE_ORDER_ON_UPDATE_PRODUCT",
  PLACE_ORDER_ON_CHANGE_SUPPLIER: "PLACE_ORDER_ON_CHANGE_SUPPLIER"
};

const searchPreferencesScreen = {
  SEARCH_PREF_ON_CANCEL: "SEARCH_PREF_ON_CANCEL",
  SEARCH_PREF_ON_SAVE_CHANGES: "SEARCH_PREF_ON_SAVE_CHANGES",
  SEARCH_PREF_ON_SEARCH_SUPPLIER: "SEARCH_PREF_ON_SEARCH_SUPPLIER",
  SEARCH_PREF_ON_SELECT_SUPPLIER: "SEARCH_PREF_ON_SELECT_SUPPLIER",
  SEARCH_PREF_ON_SUPPLIER_DRAG: "SEARCH_PREF_ON_SUPPLIER_DRAG",
  SEARCH_PREF_ON_SUPPLIERS: "SEARCH_PREF_ON_SUPPLIERS",
  SEARCH_PREF_ON_OFFERS: "SEARCH_PREF_ON_OFFERS",
  SEARCH_PREF_ON_OTHERS: "SEARCH_PREF_ON_OTHERS",
  SEARCH_PREF_ON_SHOW_OFFER_PRODUCTS: "SEARCH_PREF_ON_SHOW_OFFER_PRODUCTS",
  SEARCH_PREF_ON_SHOW_ALL_PRODUCTS: "SEARCH_PREF_ON_SHOW_ALL_PRODUCTS",
  SEARCH_PREF_ON_CLEAR_SEARCH_FIELD: "SEARCH_PREF_ON_CLEAR_SEARCH_FIELD",
  SEARCH_PREF_ON_RETAIN_SEARCH_FIELD: "SEARCH_PREF_ON_RETAIN_SEARCH_FIELD",
  SEARCH_PREF_ON_HIDE_MANUFACTURER: "SEARCH_PREF_ON_HIDE_MANUFACTURER",
  SEARCH_PREF_ON_HIDE_SUPPLIER: "SEARCH_PREF_ON_HIDE_SUPPLIER",
  SEARCH_PREF_ON_GLOBAL_SEARCH: "SEARCH_PREF_ON_GLOBAL_SEARCH",
  SEARCH_PREF_ON_ADD_SUPPLIER: "SEARCH_PREF_ON_ADD_SUPPLIER",
  SEARCH_PREF_ON_SUPPLIER_PRIORITY: "SEARCH_PREF_ON_SUPPLIER_PRIORITY",
  SEARCH_PREF_ON_SAVE_SUPPLIER_PRIORITY: "SEARCH_PREF_ON_SAVE_SUPPLIER_PRIORITY"
};

const cartScreen = {
  CART_SCREEN_VISITED: "CART_SCREEN_VISITED",
  CART_ON_SEARCH: "CART_ON_SEARCH",
  CART_ON_SEARCH_CLEAR: "CART_ON_SEARCH_CLEAR",
  CART_ON_ADD_PRODUCTS_CLICK: "CART_ON_ADD_PRODUCTS_CLICK",
  CART_ON_DELETE_ALL: "CART_ON_DELETE_ALL",
  CART_ON_EXPEND_CLICK: "CART_ON_EXPEND_CLICK",
  CART_ON_COLLAPSE_CLICK: "CART_ON_COLLAPSE_CLICK",
  CART_ON_SEARCH_CLICK: "CART_ON_SEARCH_CLICK",
  CART_ON_PLACE_ORDER_CLICK: "CART_ON_PLACE_ORDER_CLICK",
  CART_DELETE_MODAL_OPENED: "CART_DELETE_MODAL_OPENED",
  CART_DELETE_MODAL_CLOSED: "CART_DELETE_MODAL_CLOSED",
  CART_ON_SUPPLIER_WISE_PLACE_ORDER: "CART_ON_SUPPLIER_WISE_PLACE_ORDER",
  CART_ON_SUPPLIER_SETTINGS: "CART_ON_SUPPLIER_SETTINGS",
  CART_ON_SUPPLIER_WISE_DELETE_CART: "CART_ON_SUPPLIER_WISE_DELETE_CART",
  CART_ON_SUPPLIER_CART_OPEN: "CART_ON_SUPPLIER_CART_OPEN",
  CART_ON_SUPPLIER_CART_CLOSE: "CART_ON_SUPPLIER_CART_CLOSE",
  CART_QUANTITY_FIELD_CLICK: "CART_QUANTITY_FIELD_CLICK",
  CART_ON_PRODUCT_DELETE_CLICK: "CART_ON_PRODUCT_DELETE_CLICK",
  CART_ON_PRODUCT_HOLD_CLICK: "CART_ON_PRODUCT_HOLD_CLICK",
  CART_ON_PRODUCT_UN_HOLD_CLICK: "CART_ON_PRODUCT_UN_HOLD_CLICK",
  CART_SUPPLIER_FOOTER_PLACE_ORDER: "CART_SUPPLIER_FOOTER_PLACE_ORDER",
  CART_CHANGE_SUPPLIER: "CART_CHANGE_SUPPLIER",
  CART_CHANGE_SUPPLIER_AVAILABILITY_DISABLED:
    "CART_CHANGE_SUPPLIER_AVAILABILITY_DISABLED",
  CART_EDIT_QUANTITY_SAVE: "CART_EDIT_QUANTITY_SAVE",

  CART_SETTING_MODAL_OPENED: "CART_SETTING_MODAL_OPENED",
  CART_SETTING_MODAL_CLOSED: "CART_SETTING_MODAL_CLOSED",
  CART_PRODUCT_MODAL_OPENED: "CART_PRODUCT_MODAL_OPENED",
  CART_PRODUCT_MODAL_CLOSED: "CART_PRODUCT_MODAL_CLOSED",
  CART_ON_PRODUCT_SAVE_CLICK: "CART_ON_PRODUCT_SAVE_CLICK",
  CART_DISCOUNT_MODAL_OPENED: "CART_DISCOUNT_MODAL_OPENED",
  CART_DISCOUNT_MODAL_CLOSED: "CART_DISCOUNT_MODAL_CLOSED",
  CART_ON_DISCOUNT_SAVE_CLICK: "CART_ON_DISCOUNT_SAVE_CLICK",
  CART_FREE_FIELD_CLICK: "CART_FREE_FIELD_CLICK",
  CART_RATE_FIELD_CLICK: "CART_RATE_FIELD_CLICK",
  CART_DISCOUNT_FIELD_CLICK: "CART_DISCOUNT_FIELD_CLICK"
};

const repCartScreen = {
  REP_CART_SCREEN_VISITED: "REP_CART_SCREEN_VISITED",
  REP_CART_ON_OPEN_ALL: "REP_CART_ON_OPEN_ALL",
  REP_CART_ON_CLOSE_ALL: "REP_CART_ON_CLOSE_ALL",
  REP_CART_ON_SEARCH: "REP_CART_ON_SEARCH",
  REP_CART_ON_SEARCH_CLEAR: "REP_CART_ON_SEARCH_CLEAR",
  REP_CART_ON_EDIT_CART: "REP_CART_ON_EDIT_CART",
  REP_CART_ON_SAVE_CART: "REP_CART_ON_SAVE_CART",
  REP_CART_ON_EMPTY_CART: "REP_CART_ON_EMPTY_CART",
  REP_CART_ON_PLACE_ORDER: "REP_CART_ON_PLACE_ORDER",
  REP_CART_ON_DELETE_PRODUCT: "REP_CART_ON_DELETE_PRODUCT",
  REP_CART_ON_SELECT_PRODUCT: "REP_CART_ON_SELECT_PRODUCT",
  REP_CART_ON_COMMENT_UPDATE: "REP_CART_ON_COMMENT_UPDATE",
  REP_CART_ON_QUANTITY: "REP_CART_ON_QUANTITY",
  REP_CART_ON_SPECIAL_FREE_QUANTITY: "REP_CART_ON_SPECIAL_FREE_QUANTITY",
  REP_CART_ON_DISCOUNT: "REP_CART_ON_DISCOUNT",
  REP_CART_ON_SPECIAL_RATE: "REP_CART_ON_SPECIAL_RATE",
  REP_CART_ON_COMMENT_CLICK: "REP_CART_ON_COMMENT_CLICK",
  REP_CART_ON_HEADER_DISCOUNT: "REP_CART_ON_HEADER_DISCOUNT",
  REP_CART_ON_HEADER_EXPAND: "REP_CART_ON_HEADER_EXPAND",
  REP_CART_ON_HEADER_COLLAPSE: "REP_CART_ON_HEADER_COLLAPSE",
  REP_CART_ON_DISTRIBUTOR_SELECT: "REP_CART_ON_DISTRIBUTOR_SELECT",
  REP_CART_ON_DISTRIBUTOR_UNSELECT: "REP_CART_ON_DISTRIBUTOR_UNSELECT"
};

const profileScreen = {
  PROFILE_SCREEN_VISITED: "PROFILE_SCREEN_VISITED",
  PROFILE_ON_USERS: "PROFILE_ON_USERS",
  PROFILE_ON_UPDATE_STORE_LOCATION: "PROFILE_ON_UPDATE_STORE_LOCATION",
  PROFILE_ON_DOWNLOAD_DRUG_FILE: "PROFILE_ON_DOWNLOAD_DRUG_FILE",
  PROFILE_ON_UPLOAD_DRUG_FILE: "PROFILE_ON_UPLOAD_DRUG_FILE",
  PROFILE_ON_REMOVE_DRUG_FILE: "PROFILE_ON_REMOVE_DRUG_FILE",
  PROFILE_ON_PREVIEW_FILE: "PROFILE_ON_PREVIEW_FILE",
  PROFILE_ON_SAVE: "PROFILE_ON_SAVE",
  PROFILE_ON_ENTER_BUSINESS_NAME: "PROFILE_ON_ENTER_BUSINESS_NAME",
  PROFILE_ON_ENTER_DRUG_LICENSE_NUMBER: "PROFILE_ON_ENTER_DRUG_LICENSE_NUMBER",
  PROFILE_ON_ENTER_DRUG_LICENSE_EXPIRY: "PROFILE_ON_ENTER_DRUG_LICENSE_EXPIRY",
  PROFILE_ON_ENTER_GST: "PROFILE_ON_ENTER_GST",
  PROFILE_ON_ENTER_PASSWORD: "PROFILE_ON_ENTER_PASSWORD",
  PROFILE_ON_CONFIRM_PASSWORD: "PROFILE_ON_CONFIRM_PASSWORD",
  PROFILE_ON_CONTACT_PERSON: "PROFILE_ON_CONTACT_PERSON",
  PROFILE_ON_EMAIL_ID: "PROFILE_ON_EMAIL_ID",
  PROFILE_ON_ADDRESS_LINE_ONE: "PROFILE_ON_ADDRESS_LINE_ONE",
  PROFILE_ON_ADDRESS_LINE_TWO: "PROFILE_ON_ADDRESS_LINE_TWO",
  PROFILE_ON_CITY: "PROFILE_ON_CITY",
  PROFILE_ON_STATE: "PROFILE_ON_STATE",
  PROFILE_ON_DISTRICT: "PROFILE_ON_DISTRICT",
  PROFILE_ON_PINCODE: "PROFILE_ON_PINCODE"
};

const logisticsScreen = {
  LOGISTICS_SCREEN_VISITED: "LOGISTICS_SCREEN_VISITED",
  LOGISTICS_ON_SEARCH: "LOGISTICS_ON_SEARCH",
  LOGISTICS_ON_SEARCH_CLEAR: "LOGISTICS_ON_SEARCH_CLEAR",
  LOGISTICS_ON_CREATE_LOGISTICS: "LOGISTICS_ON_CREATE_LOGISTICS",
  LOGISTICS_ON_TRACK: "LOGISTICS_ON_TRACK",
  LOGISTICS_ON_CANCEL: "LOGISTICS_ON_CANCEL"
};

const leftNavBarActions = {
  NAVBAR_ON_OPEN: "NAVBAR_ON_OPEN",
  NAVBAR_ON_CLOSE: "NAVBAR_ON_CLOSE",
  NAVBAR_ON_PLACE_ORDER: "NAVBAR_ON_PLACE_ORDER",
  NAVBAR_ON_DASHBOARD: "NAVBAR_ON_DASHBOARD",
  NAVBAR_ON_ALL_ORDERS: "NAVBAR_ON_ALL_ORDERS",
  NAVBAR_ON_INVENTORY: "NAVBAR_ON_INVENTORY",
  NAVBAR_ON_BROADCAST: "NAVBAR_ON_BROADCAST",
  NAVBAR_ON_PAYMENTS: "NAVBAR_ON_PAYMENTS",
  NAVBAR_ON_QUOTATIONS: "NAVBAR_ON_QUOTATIONS",
  NAVBAR_ON_INVOICES: "NAVBAR_ON_INVOICES",
  NAVBAR_ON_SUPPLIERS: "NAVBAR_ON_SUPPLIERS",
  NAVBAR_ON_GROUPS: "NAVBAR_ON_GROUPS",
  NAVBAR_ON_CUSTOMERS: "NAVBAR_ON_CUSTOMERS",
  NAVBAR_ON_LEDGER: "NAVBAR_ON_LEDGER",
  NAVBAR_ON_REPRESENTATIVES: "NAVBAR_ON_REPRESENTATIVES",
  NAVBAR_ON_LOGISTICS: "NAVBAR_ON_LOGISTICS",
  NAVBAR_ON_COUPONS: "NAVBAR_ON_COUPONS",
  NAVBAR_ON_OFFERS: "NAVBAR_ON_OFFERS",
  NAVBAR_ON_CREDIT_NOTES: "NAVBAR_ON_CREDIT_NOTES",
  NAVBAR_ON_SUBSCRIPTIONS: "NAVBAR_ON_SUBSCRIPTIONS",
  NAVBAR_ON_SETTINGS: "NAVBAR_ON_SETTINGS",
  NAVBAR_ON_RETURNS: "NAVBAR_ON_RETURNS",
  NAVBAR_ON_CREDIT: "NAVBAR_ON_CREDIT",
  NAVBAR_ON_LEDGER_ORDER: "NAVBAR_ON_LEDGER_ORDER",
  NAVBAR_ON_DRUG_INFO: "NAVBAR_ON_DRUG_INFO",
  NAVBAR_ON_LOGOUT: "NAVBAR_ON_LOGOUT"
};

const headerActions = {
  HEADER_ON_NOTIFICATION: "HEADER_ON_NOTIFICATION",
  HEADER_ON_RETAILER_CART: "HEADER_ON_RETAILER_CART",
  HEADER_ON_CART: "HEADER_ON_CART",
  HEADER_ON_PROFILE: "HEADER_ON_PROFILE",
  HEADER_ON_SUPPORT: "HEADER_ON_SUPPORT",
  HEADER_ON_SALE_ROLE: "HEADER_ON_SALE_ROLE",
  HEADER_ON_PURCHASE_ROLE: "HEADER_ON_PURCHASE_ROLE"
};

const usersScreen = {
  USERS_ON_LOG_OUT_ALL: "USERS_LOG_OUT_ALL",
  USERS_ON_ADD: "USERS_ON_ADD",
  USERS_ON_CLOSE: "USERS_ON_CLOSE",
  USERS_ON_LOG_OUT: "USERS_ON_LOG_OUT",
  USERS_ON_DELETE: "USERS_ON_DELETE",
  USERS_ADD_ON_ENTER_NAME: "USERS_ADD_ON_ENTER_NAME",
  USERS_ADD_ON_ENTER_PHONE_NUMBER: "USERS_ADD_ON_ENTER_PHONE_NUMBER",
  USERS_ADD_ON_ADD: "USERS_ADD_ON_ADD",
  USERS_ADD_ON_AD_CLOSE: "USERS_ADD_ON_AD_CLOSE"
};

const updateLocation = {
  LOCATION_ON_CLOSE: "LOCATION_ON_CLOSE",
  LOCATION_ON_UPDATE_WITH_CURR_LOCATION:
    "LOCATION_ON_UPDATE_WITH_CURR_LOCATION",
  LOCATION_ON_LATITUDE: "LOCATION_ON_LATITUDE",
  LOCATION_ON_LONGITUDE: "LOCATION_ON_LONGITUDE",
  LOCATION_ON_SHOW_SELECTED_LOCA_IN_MAP:
    "LOCATION_ON_SHOW_SELECTED_LOCA_IN_MAP",
  LOCATION_ON_SAVE_LOCATION: "LOCATION_ON_SAVE_LOCATION"
};

const bulkOrder = {
  BULK_ORDER_ON_SELECT_FILE: "BULK_ORDER_ON_SELECT_FILE",
  BULK_ORDER_ON_DELETE_FILE: "BULK_ORDER_ON_DELETE_FILE",
  BULK_ORDER_ON_UPLOAD_FILE: "BULK_ORDER_ON_UPLOAD_FILE",
  BULK_ORDER_ON_EDIT_MAPPING: "BULK_ORDER_ON_EDIT_MAPPING",
  BULK_ORDER_ON_HEADER_ROW_NUMBER: "BULK_ORDER_ON_HEADER_ROW_NUMBER",
  BULK_ORDER_ON_HEADER_PROCEED: "BULK_ORDER_ON_HEADER_PROCEED",
  BULK_ORDER_ON_HEADER_COLUMNS_NUMBER: "BULK_ORDER_ON_HEADER_COLUMNS_NUMBER",
  BULK_ORDER_ON_INVENTORY_MAPPING: "BULK_ORDER_ON_INVENTORY_MAPPING",
  BULK_ORDER_ON_FIND_SELECT_PRODUCT: "BULK_ORDER_ON_FIND_SELECT_PRODUCT",
  BULK_ORDER_ON_SELECT_SUPPLIER_DELETE: "BULK_ORDER_ON_SELECT_SUPPLIER_DELETE",
  BULK_ORDER_ON_SEARCH: "BULK_ORDER_ON_SEARCH",
  BULK_ORDER_ON_CLOSE: "BULK_ORDER_ON_CLOSE",
  BULK_ORDER_ON_SELECT_SINGLE_SUPPLIER: "BULK_ORDER_ON_SELECT_SINGLE_SUPPLIER",
  BULK_ORDER_ON_REMOVE_SINGLE_SUPPLIER: "BULK_ORDER_ON_REMOVE_SINGLE_SUPPLIER",
  BULK_ORDER_ON_PRODUCT_MAPPING: "BULK_ORDER_ON_PRODUCT_MAPPING",
  BULK_ORDER_ON_SUPPLIER_MAPPING: "BULK_ORDER_ON_SUPPLIER_MAPPING",
  BULK_ORDER_ON_SORT_PRODUCT_BY_NAME: "BULK_ORDER_ON_SORT_PRODUCT_BY_NAME",
  BULK_ORDER_ON_DOWNLOAD: "BULK_ORDER_ON_DOWNLOAD",
  BULK_ORDER_ON_DELETE: "BULK_ORDER_ON_DELETE",
  BULK_ORDER_ON_UPLOAD: "BULK_ORDER_ON_UPLOAD",
  BULK_ORDER_ON_FINALIS_SELECTED_PRODUCTS:
    "BULK_ORDER_ON_FINALIS_SELECTED_PRODUCTS",
  BULK_ORDER_ON_DELETE_PURCHASE_ORDERS: "BULK_ORDER_ON_DELETE_PURCHASE_ORDERS",
  BULK_ORDER_ON_UPDATE_PURCHASE_ORDERS: "BULK_ORDER_ON_UPDATE_PURCHASE_ORDERS",
  BULK_ORDER_ON_SHOW_INVENTORY: "BULK_ORDER_ON_SHOW_INVENTORY",
  BULK_ORDER_ON_INVENTORY_CHANGE: "BULK_ORDER_ON_INVENTORY_CHANGE",
  BULK_ORDER_ON_PRODUCT_DELETE: "BULK_ORDER_ON_PRODUCT_DELETE"
};

const helpAndSupport = {
  HELP_SUPPORT_SCREEN_VISITED: "HELP_SUPPORT_SCREEN_VISITED",
  HELP_SUPPORT_ON_TUTORIALS: "HELP_SUPPORT_ON_TUTORIALS",

  HELP_SUPPORT_ON_PRODUCT_SEARCH: "HELP_SUPPORT_ON_PRODUCT_SEARCH",
  HELP_SUPPORT_ON_PRODUCT_INCORRECT: "HELP_SUPPORT_ON_PRODUCT_INCORRECT",
  HELP_SUPPORT_ON_SUPPLIER_UNAVAILABLE: "HELP_SUPPORT_ON_SUPPLIER_UNAVAILABLE",
  HELP_SUPPORT_ON_PRODUCT_SEARCH_OTHER: "HELP_SUPPORT_ON_PRODUCT_SEARCH_OTHER",

  HELP_SUPPORT_ON_CART: "HELP_SUPPORT_ON_CART",
  HELP_SUPPORT_ON_CANNOT_ADD_PRODUCTS: "HELP_SUPPORT_ON_CANNOT_ADD_PRODUCTS",
  HELP_SUPPORT_ON_CANNOT_CLEAR_CART: "HELP_SUPPORT_ON_CANNOT_CLEAR_CART",
  HELP_SUPPORT_ON_MISSING_CART: "HELP_SUPPORT_ON_MISSING_CART",
  HELP_SUPPORT_ON_CANNOT_EDIT_CART: "HELP_SUPPORT_ON_CANNOT_EDIT_CART",
  HELP_SUPPORT_ON_CART_OTHER: "HELP_SUPPORT_ON_CART_OTHER",

  HELP_SUPPORT_ON_ORDER: "HELP_SUPPORT_ON_ORDER",
  HELP_SUPPORT_ON_CANNOT_PLACE_ORDER: "HELP_SUPPORT_ON_CANNOT_PLACE_ORDER",
  HELP_SUPPORT_ON_MISSING_ORDER: "HELP_SUPPORT_ON_MISSING_ORDER",
  HELP_SUPPORT_ON_DIDNOT_PLACE_ORDER: "HELP_SUPPORT_ON_DIDNOT_PLACE_ORDER",
  HELP_SUPPORT_ON_INCORRECT_QUANTITY_ORDER:
    "HELP_SUPPORT_ON_INCORRECT_QUANTITY_ORDER",
  HELP_SUPPORT_ON_ORDER_OTHER: "HELP_SUPPORT_ON_ORDER_OTHER",

  HELP_SUPPORT_ON_ACCOUNT_PRIVACY: "HELP_SUPPORT_ON_ACCOUNT_PRIVACY",
  HELP_SUPPORT_ON_BLOCK_ACCOUNT: "HELP_SUPPORT_ON_BLOCK_ACCOUNT",
  HELP_SUPPORT_ON_UPDATE_BUSINESS: "HELP_SUPPORT_ON_UPDATE_BUSINESS",
  HELP_SUPPORT_ON_DATA_SAFETY: "HELP_SUPPORT_ON_DATA_SAFETY",
  HELP_SUPPORT_ON_ACCOUNT_PRIVACY_OTHER:
    "HELP_SUPPORT_ON_ACCOUNT_PRIVACY_OTHER",

  HELP_SUPPORT_ON_ISSUE_DESCRIPTION: "HELP_SUPPORT_ON_ISSUE_DESCRIPTION",
  HELP_SUPPORT_ON_UPLOAD_IMAGE: "HELP_SUPPORT_ON_UPLOAD_IMAGE",
  HELP_SUPPORT_ON_REMOVE_IMAGE: "HELP_SUPPORT_ON_REMOVE_IMAGE",
  HELP_SUPPORT_ON_CALL_BACK_REQUEST: "HELP_SUPPORT_ON_CALL_BACK_REQUEST",
  HELP_SUPPORT_ON_LANGUAGE_CHANGE: "HELP_SUPPORT_ON_LANGUAGE_CHANGE",
  HELP_SUPPORT_ON_VIDEO_OPEN: "HELP_SUPPORT_ON_VIDEO_OPEN"
};

const drugInfoScreen = {
  DRUG_INFO_SCREEN_VISITED: "DRUG_INFO_SCREEN_VISITED",
  DRUG_INFO_ON_SEARCH: "DRUG_INFO_ON_SEARCH",
  DRUG_INFO_ON_SEARCH_CLEAR: "DRUG_INFO_ON_SEARCH_CLEAR",
  DRUG_INFO_ON_DRUG_ITEM: "DRUG_INFO_ON_DRUG_ITEM",
  DRUG_INFO_ON_DRUG_ITEM_ADD: "DRUG_INFO_ON_DRUG_ITEM_ADD",
  DRUG_INFO_DETAILS_SCREEN_VISITED: "DRUG_INFO_DETAILS_SCREEN_VISITED",
  DRUG_INFO_DETAILS_ON_ADD_CART: "DRUG_INFO_DETAILS_ON_ADD_CART",
  DRUG_INFO_DETAILS_ON_SUBSTITUTES: "DRUG_INFO_DETAILS_ON_SUBSTITUTES",
  DRUG_INFO_DETAILS_ON_USES_EXPEND: "DRUG_INFO_DETAILS_ON_USES_EXPEND",
  DRUG_INFO_DETAILS_ON_USES_COLLAPSE: "DRUG_INFO_DETAILS_ON_USES_COLLAPSE",
  DRUG_INFO_DETAILS_ON_SIDE_EFFECTS_EXPEND: "DRUG_INFO_ON_SIDE_EFFECTS_EXPEND",
  DRUG_INFO_DETAILS_ON_SIDE_EFFECTS_COLLAPSE:
    "DRUG_INFO_ON_SIDE_EFFECTS_COLLAPSE",
  DRUG_INFO_DETAILS_ON_FACTS_EXPEND: "DRUG_INFO_DETAILS_ON_FACTS_EXPEND",
  DRUG_INFO_DETAILS_ON_FACTS_COLLAPSE: "DRUG_INFO_DETAILS_ON_FACTS_COLLAPSE"
};

const changeSupplier = {
  CHANGE_SUPPLIER_ON_SEARCH: "CHANGE_SUPPLIER_ON_SEARCH",
  CHANGE_SUPPLIER_ON_SUBMIT: "CHANGE_SUPPLIER_ON_SUBMIT",
  CHANGE_SUPPLIER_ON_SELECT_ITEM: "CHANGE_SUPPLIER_ON_SELECT_ITEM"
};

const fileConfigure = {
  ON_DOWNLOAD_SAMPLE_FILE: "DOWNLOAD_SAMPLE_FILE",
  ON_DELETE_FILE: "ON_DELETE_FILE",
  ON_SELECT_A_FILE_TO_UPLOAD: "ON_SELECT_A_FILE_TO_UPLOAD",
  ON_EDIT_MAPPING: "ON_EDIT_MAPPING",
  ON_UPLOAD_FILE: "ON_EDIT_MAPPING",
  ON_NEXT: "ON_NEXT",
  ON_PROCEED: "ON_PROCEED"
};

export const EventType = {
  ...loginScreenEventTypes,
  ...registrationScreensEventTypes,
  ...forgotPasswordScreen,
  ...otpScreen,
  ...confirmPasswordScreen,
  ...notificationScreen,
  ...analyticsScreen,
  ...allOrderScreen,
  ...orderDetailsScreen,
  ...paymentsScreen,
  ...invoicesScreen,
  ...invoiceDetailsScreen,
  ...suppliersScreen,
  ...addSupplierScreen,
  ...creditScreen,
  ...inventoryScreen,
  ...customersScreen,
  ...landingPage,
  ...ledgerScreen,
  ...representativeScreen,
  ...attendanceScreen,
  ...trackingScreen,
  ...settingsScreen,
  ...ledgerOrder,
  ...placeOrderScreen,
  ...searchPreferencesScreen,
  ...cartScreen,
  ...profileScreen,
  ...logisticsScreen,
  ...leftNavBarActions,
  ...headerActions,
  ...usersScreen,
  ...updateLocation,
  ...bulkOrder,
  ...repCartScreen,
  ...helpAndSupport,
  ...drugInfoScreen,
  ...changeSupplier,
  ...fileConfigure,

  CREDIT_PROVIDER_SELECTED: "CREDIT_PROVIDER_SELECTED",
  CREDIT_PROVIDER_PAYMENT_CREATED: "CREDIT_PROVIDER_PAYMENT_CREATED",
  CREDIT_PROVIDER_ONBOARD: "CREDIT_PROVIDER_ONBOARD",
  PAY_WITH_CREDIT: "PAY_WITH_CREDIT"
};

export const EventAction = {
  credit_provider_selected: "Credit Provider Selected",
  credit_payment_created: "Credit Payment Created",
  credit_provider_onboard: "Credit Provider Onboard",
  open_pay_with_credit_modal: "Open Pay With Credit Modal"
};

export const EventCategory = {
  finance: "finance"
};

export const ReactGATracker = async (
  eventType,
  eventCategory = EventCategory.finance
) => {
  try {
    ReactGA.event({
      action: eventType,
      category: eventCategory
    });
  } catch (err) {
    console.log(err);
  }
};
