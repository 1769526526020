const urls = {
  GAMMA: "https://gamma.valuemedi.com/",
  PROD: "https://www.valuemedi.com/",
  LOCAL: "http://localhost:8081/"
};

export const GTMTags = {
  BETA: "G-XS3DN1T9HS",
  PROD: "G-TDQPF5HHCJ"
};

const baseURL = urls[process.env.REACT_APP_URL_ENV] ?? urls.GAMMA;

const defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json"
};

const loginWithTokenURL = `${baseURL}api/auth/tokenLogin`;
const loginURL = `${baseURL}api/auth/login`;
const phoneNumberLogin = `${baseURL}api/auth/login/number`;
const refreshToken = `${baseURL}api/auth/refresh`;
const signupURL = `${baseURL}api/auth/user-registration`;
const logoutURL = `${baseURL}api/auth/logout`;
const otpGenerate = `${baseURL}api/v1/otp/generate`;
const otpValidate = `${baseURL}api/v1/otp/validate`;
const resetPassword = `${baseURL}api/v1/utils/reset-password`;

const analytics = `${baseURL}api/v1/analytics/all`;
const analyticsV2 = `${baseURL}api/v1/analytics/v2`;
const uploadBroadcasts = `${baseURL}api/v1/broadcast/parse-broadcasts`;
const broadcastDetails = `${baseURL}api/v1/broadcast/broadcasts`;
const broadcastTemplates = `${baseURL}api/v1/broadcast/broadcast-templates`;
const broadcastLimit = `${baseURL}api/v1/broadcast/broadcast-limit`;
const couponsSummary = `${baseURL}api/v1/coupon/summary`;
const coupons = `${baseURL}api/v1/coupon/all`;
const couponRedeem = `${baseURL}api/v1/coupon/redeem`;
const supplierCreditProviders = `${baseURL}api/v1/credit-provider`;
const creditDetails = `${baseURL}api/v1/credit-provider/details`;
const createCreditPayment = `${baseURL}api/v1/credit-provider/payment`;
const allCreditProviders = `${baseURL}api/v1/credit-provider/all`;
const creditFlowRedirect = `${baseURL}api/v1/credit-provider/redirect`;
const creditProviders = `${baseURL}api/v1/credit-provider/details/grid`;
const searchSupplierProductDetails = `${baseURL}api/v1/inventory/customer/search`;
const globalSupplierIds = `${baseURL}api/v1/inventory/global-suppliers`;
const getSuggestedProducts = `${baseURL}api/v1/inventory/customer/suggested`;
const inventoryDetails = `${baseURL}api/v1/inventory/supplier/all`;
const supplierProductsList = `${baseURL}api/v1/inventory/supplier/list`;
const updateInventoryStatus = `${baseURL}api/v1/inventory/update-visibility`;
const uploadInventory = `${baseURL}api/v1/inventory/inventory-ingestion`;
const updateThreshold = `${baseURL}api/v1/inventory/update/threshold`;
const invoiceDetailsWithUpdateId = `${baseURL}api/v1/invoice/details-for-update`;
const invoiceDetails = `${baseURL}api/v1/invoice`;
const invoiceStatus = `${baseURL}api/v1/invoice/statuses`;
const getAllInvoices = `${baseURL}api/v1/invoice/find-all`;
const outstandingInvoices = `${baseURL}api/v1/invoice/outstanding`;
const supplierLedger = `${baseURL}api/v1/ledger/all`;
const getRepresentativeLedgers = `${baseURL}api/v1/ledger/representative`;
const updateLedger = `${baseURL}api/v1/ledger/update`;
const uploadLedger = `${baseURL}api/v1/ledger/ledger-ingestion`;
const removeLocation = `${baseURL}api/v1/ledger/remove-location`;
const addToCart = `${baseURL}api/v1/cart-new/create`;
const cartDetailsURL = `${baseURL}api/v1/cart-new/get-cart-details`;
const updateCart = `${baseURL}api/v1/cart-new/update`;
const updateCartHold = `${baseURL}api/v1/cart-new/hold`;
const clearCart = `${baseURL}api/v1/cart-new/clear`;
const offerCreate = `${baseURL}api/v1/offer/create`;
const offerUpdate = `${baseURL}api/v1/offer/update`;
const getOffers = `${baseURL}api/v1/offer/all`;
const bannerImages = `${baseURL}api/v1/offer/banner-images?env=web`;
const deleteOffer = `${baseURL}api/v1/offer/delete`;
const cartPlaceOrder = `${baseURL}api/v1/order/create`;
const orderStatuses = `${baseURL}api/v1/order/allStatuses`;
const findOrders = `${baseURL}api/v1/order/find-orders`;
const getOrdersDateView = `${baseURL}api/v1/order/date-view`;
const getOrderDetails = `${baseURL}api/v1/order`;
const reorder = `${baseURL}api/v1/order/reorder`;
const updateOrderByAction = `${baseURL}api/v1/order/action`;
const receivedOrdersCustomers = `${baseURL}api/v1/order/pending/customers`;
const updateCustomerOrdersToInvoicing = `${baseURL}api/v1/order/manual-invoicing`;
const findOrdersByProductSearch = `${baseURL}api/v1/order/find-orders/productSearch`;
const createLedgerOrder = `${baseURL}api/v1/order/create-ledger-order`;
const saveParserConfig = `${baseURL}api/v1/parser-config/save`;
const fetchParserConfigTemplate = `${baseURL}api/v1/parser-config/fetch-template`;
const fetchFileHeader = `${baseURL}api/v1/parser-config/fetch-header`;
const raisePaymentRequest = `${baseURL}api/v1/payment/raise-request`;
const payPayment = `${baseURL}api/v1/payment/pay`;
const updatePayment = `${baseURL}api/v1/payment/update`;
const paymentRequests = `${baseURL}api/v1/payment/requests`;
const paymentOutstanding = `${baseURL}api/v1/payment/outstanding`;
const paymentCollectionStatuses = `${baseURL}api/v1/payment/all-collection-statuses`;
const paymentsDownload = `${baseURL}api/v1/payment/report`;
const paymentTypes = `${baseURL}api/v1/payment/types`;
const deletePreCartItem = `${baseURL}api/v1/preCart/delete`;
const updatePreCartItem = `${baseURL}api/v1/preCart/update`;
const addToPreCart = `${baseURL}api/v1/preCart/create`;
const getPreCarts = `${baseURL}api/v1/preCart/all`;
const addToCartFromPreCarts = `${baseURL}api/v1/preCart/add-to-cart`;
const getAllDrugInfo = `${baseURL}api/v1/product/all`;
const getDrugSubs = `${baseURL}api/v1/product/find`;
const suggestProduct = `${baseURL}api/v1/product/send-product`;
const uploadPurchaseOrderV2 = `${baseURL}api/v2/purchaseOrder/upload`;
const purchaseOrderSupplierMapping = `${baseURL}api/v2/purchaseOrder/supplier-mapping`;
const purchaseOrderInventoryMapping = `${baseURL}api/v2/purchaseOrder/inventory-mapping`;
const updatePurchaseOrderDetail = `${baseURL}api/v2/purchaseOrder/update`;
const allPurchaseOrdersV2 = `${baseURL}api/v2/purchaseOrder/all`;
const deletePoDetails = `${baseURL}api/v2/purchaseOrder/details`;
const suggestProductForPO = `${baseURL}api/v2/purchaseOrder/suggest-product`;
const quotations = `${baseURL}api/v1/quotation/all`;
const quotationDetails = `${baseURL}api/v1/quotation/details/`;
const createQuotation = `${baseURL}api/v1/quotation/create`;
const quotationOrder = `${baseURL}api/v1/quotation/order`;
const deactivateQuotation = `${baseURL}api/v1/quotation/deactivate/`;
const supplierSettings = `${baseURL}api/v1/settings/fetch`;
const flipPaymentNotificationSetting = `${baseURL}api/v1/settings/update/paymentNotification`;
const getAllSubscriptions = `${baseURL}api/v1/subscriptions/all`;
const subscriptionPayment = `${baseURL}api/v1/subscriptions/pay`;
const createSupplierCustomerURL = `${baseURL}api/v1/customers/associate-divisions`;
const allAssociatedUsers = `${baseURL}api/v1/customers/all`;
const supplierDetails = `${baseURL}api/v1/customers/approved`;
const allAssociatedUsersList = `${baseURL}api/v1/customers/all/list`;
const customersUpdate = `${baseURL}api/v1/customers/update`;
const deleteAssociation = `${baseURL}api/v1/customers/`;
const customersBlock = `${baseURL}api/v1/customers/status`;
const suppliersPriority = `${baseURL}api/v1/customers/priority`;
const suggestSupplier = `${baseURL}api/v1/customers/suggest-supplier`;
const updateSalesmanCodes = `${baseURL}api/v1/representative/update-salesman-codes/`;
const representatives = `${baseURL}api/v1/representative/all`;
const representativesList = `${baseURL}api/v1/representative/all-list`;
const supplierRepresentativeList = `${baseURL}api/v1/representative/all-list/0`;
const updateRepresentative = `${baseURL}api/v1/representative/update`;
const representativeUpdate = `${baseURL}api/v1/representative/update-details`;
const representativeCreate = `${baseURL}api/v1/representative/create`;
const supervisorsList = `${baseURL}api/v1/representative/supervisors`;
const getRepresentativeAttendance = `${baseURL}api/v1/representative/attendance`;
const supportCreateRequest = `${baseURL}api/v1/support/createRequest`;
const userStatuses = `${baseURL}api/v1/user/all-user-statuses`;
const requestStatuses = `${baseURL}api/v1/user/all-request-statuses`;
const updateUserProfile = `${baseURL}api/v1/user/update-profile`;
const storeUserDeviceDetails = `${baseURL}api/v1/user/session-device`;
const userProfile = `${baseURL}api/v1/user/profile`;
const searchUsersURL = `${baseURL}api/v1/user/search`;
const newUsersURL = `${baseURL}api/v1/user/new`;
const switchRoleUrl = `${baseURL}api/v1/user/switch`;
const tokenByUserId = `${baseURL}api/v1/user/token`;
const userSettings = `${baseURL}api/v1/user/settings/fetch`;
const updateSettings = `${baseURL}api/v1/user/settings/save`;
const userCreditConsent = `${baseURL}api/v1/user-consent/fetch`;
const userCreditConsentSave = `${baseURL}api/v1/user-consent/save`;
const userGroups = `${baseURL}api/v1/userGroup/all`;
const userGroupsList = `${baseURL}api/v1/userGroup/list`;
const userGroupURL = `${baseURL}api/v1/userGroup/`;
const persistUserGroup = `${baseURL}api/v1/userGroup/persist`;
const updateLocation = `${baseURL}api/v1/location/update`;
const getRepresentativeLocation = `${baseURL}api/v1/location/get`;
const userNumber = `${baseURL}api/v1/user-number`;
const removeUserNumber = `${baseURL}api/v1/user-number/session`;
const removeAllUserNumbers = `${baseURL}api/v1/user-number/all-sessions`;
const allStates = `${baseURL}api/v1/utils/states`;
const allDistricts = `${baseURL}api/v1/utils/districts/`;
const fullPageBanner = `${baseURL}api/v1/utils/full-banner`;
const newNotifications = `${baseURL}api/v1/notifications/new`;
const allNotifications = `${baseURL}api/v1/notifications/all`;
const logisticOrders = `${baseURL}api/v1/logistics/grid`;
const logisticsDropdown = `${baseURL}api/v1/logistics/destinations`;
const logisticsQuotes = `${baseURL}api/v1/logistics/quotes`;
// const createLogisticsOrder = `${baseURL}api/v1/logistics/order`;
const createLogisticsOrder = `${baseURL}api/v1/logistics/order-pay`;
const cancelLogisticsOrder = `${baseURL}api/v1/logistics/cancel`;

const APIConstants = {
  baseURL,
  defaultHeaders,
  loginWithTokenURL,
  loginURL,
  phoneNumberLogin,
  refreshToken,
  signupURL,
  logoutURL,
  otpGenerate,
  otpValidate,
  resetPassword,
  analytics,
  analyticsV2,
  uploadBroadcasts,
  broadcastDetails,
  broadcastTemplates,
  broadcastLimit,
  couponsSummary,
  coupons,
  couponRedeem,
  supplierCreditProviders,
  creditDetails,
  createCreditPayment,
  allCreditProviders,
  creditFlowRedirect,
  creditProviders,
  searchSupplierProductDetails,
  globalSupplierIds,
  getSuggestedProducts,
  inventoryDetails,
  supplierProductsList,
  updateInventoryStatus,
  uploadInventory,
  updateThreshold,
  invoiceDetailsWithUpdateId,
  invoiceDetails,
  invoiceStatus,
  getAllInvoices,
  outstandingInvoices,
  supplierLedger,
  getRepresentativeLedgers,
  updateLedger,
  uploadLedger,
  removeLocation,
  addToCart,
  cartDetailsURL,
  updateCart,
  updateCartHold,
  clearCart,
  offerCreate,
  offerUpdate,
  getOffers,
  bannerImages,
  deleteOffer,
  cartPlaceOrder,
  orderStatuses,
  findOrders,
  getOrdersDateView,
  getOrderDetails,
  reorder,
  updateOrderByAction,
  receivedOrdersCustomers,
  updateCustomerOrdersToInvoicing,
  findOrdersByProductSearch,
  createLedgerOrder,
  saveParserConfig,
  fetchParserConfigTemplate,
  fetchFileHeader,
  raisePaymentRequest,
  payPayment,
  updatePayment,
  paymentRequests,
  paymentOutstanding,
  paymentCollectionStatuses,
  paymentsDownload,
  paymentTypes,
  deletePreCartItem,
  updatePreCartItem,
  addToPreCart,
  getPreCarts,
  addToCartFromPreCarts,
  getAllDrugInfo,
  getDrugSubs,
  suggestProduct,
  uploadPurchaseOrderV2,
  purchaseOrderSupplierMapping,
  purchaseOrderInventoryMapping,
  updatePurchaseOrderDetail,
  allPurchaseOrdersV2,
  deletePoDetails,
  suggestProductForPO,
  quotations,
  quotationDetails,
  createQuotation,
  quotationOrder,
  deactivateQuotation,
  supplierSettings,
  flipPaymentNotificationSetting,
  getAllSubscriptions,
  subscriptionPayment,
  createSupplierCustomerURL,
  allAssociatedUsers,
  supplierDetails,
  allAssociatedUsersList,
  customersUpdate,
  deleteAssociation,
  customersBlock,
  suppliersPriority,
  suggestSupplier,
  updateSalesmanCodes,
  representatives,
  representativesList,
  supplierRepresentativeList,
  updateRepresentative,
  representativeUpdate,
  representativeCreate,
  supervisorsList,
  getRepresentativeAttendance,
  supportCreateRequest,
  userStatuses,
  requestStatuses,
  updateUserProfile,
  storeUserDeviceDetails,
  userProfile,
  searchUsersURL,
  newUsersURL,
  switchRoleUrl,
  tokenByUserId,
  userSettings,
  updateSettings,
  userCreditConsent,
  userCreditConsentSave,
  userGroups,
  userGroupsList,
  userGroupURL,
  persistUserGroup,
  updateLocation,
  getRepresentativeLocation,
  userNumber,
  removeUserNumber,
  removeAllUserNumbers,
  allStates,
  allDistricts,
  fullPageBanner,
  newNotifications,
  allNotifications,
  logisticOrders,
  logisticsDropdown,
  logisticsQuotes,
  createLogisticsOrder,
  cancelLogisticsOrder
};

export default APIConstants;
